<template>
  <a-modal
    v-model="show"
    title="新增/编辑用户组"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="cancel">
    <a-spin :spinning="loading">
      <a-form-data ref="form" v-model="row" :setting="setting" :render-data="renderData" />
    </a-spin>
  </a-modal>
</template>
<script>
  import API from '@/api'
  export default {
    props: {
      row: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        show: true,
        loading: false,
        setting: {
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
          rules: {
            name: [{ required: true, message: '请输入用户组名称', trigger: 'change' }],
            status: [{ required: true, message: '请选择状态', trigger: 'change' }],
            desc: [{ required: true, message: '请输入描述', trigger: 'change' }]
          }
        },
        renderData: {
          name: {
            label: '用户组名称',
            valid: true,
          },
          status: {
            label: '状态',
            valid: true,
            renderName: 'aRadioGroup',
            options: [
              { label: '启用', value: 1 },
              { label: '禁用', value: 2 }
            ]
          },
          desc: {
            label: '描述',
            valid: true,
            props: { type: 'textarea', row: 3 }
          }
        }
      }
    },
    methods: {
      async ok() {
        try {
          this.loading = true
          await this.$request({
            url: this.row.id ? API.departmentManagement.groupEdit : API.departmentManagement.groupAdd,
            method: 'post',
            data: this.row
          })
          this.$message.success('操作成功')
          await this.cancel()
        } catch (e) {
          console.log('e', e)
        } finally {
          this.loading = false
        }
      },
      cancel() {
        this.$emit('cancel')
      }
    }
  }
</script>
