<template>
  <div class="my-content">
    <a-search-form
      ref="searchForm"
      v-model="form"
      :loading="loading"
      :render-data="renderData"
      @handleSearch="handleSearch"
      @handleReset="handleReset">
      <a-button slot="button" type="primary" icon="plus" @click="showAddEdit = true; row = {}">新增</a-button>
    </a-search-form>
    <a-tables
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :page="page"
      @onChange="onChange"
    >
    </a-tables>
    <add-edit v-if="showAddEdit" :row="row" @cancel="showAddEdit = false; handleSearch()"/>
    <a-modal title="授权" :visible="visible" @ok="handleOk" @cancel="visible = false" >
      <div style="max-height: 450px;overflow-y: auto">
        <a-tree
          :defaultExpandAll="true"
          :checkable="true"
          v-model="checkedKeys"
          :treeData="treeData"
          :replace-fields="{
            children: 'sub',
            key: 'code',
            title: 'name',
          }"
          @check="check"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
  import API from '@/api'
  import AddEdit from "./addEdit";
  export default {
    name: 'departmentManagement',
    components: {AddEdit},
    data() {
      const that = this
      return {
        form: {},
        loading: false,
        renderData: {
          keyword: {
            label: '关键字',
            props: { placeholder: '请填写关键字' }
          }
        },
        columns: [
          { title: 'id', dataIndex: 'id' },
          { title: '用户组名称', dataIndex: 'name' },
          { title: '描述', dataIndex: 'desc' },
          {
            title: '状态',
            renderType: 'html',
            dataIndex: 'status',
            render: (row) => {
              return row.status === 1 ? '启用' : '禁用'
            }
          },
          {
            title: '操作',
            width: 220,
            dataIndex: 'handle',
            renderType: 'handle',
            childrens: [
              {
                label: '编辑',
                default(row) {
                  that.showAddEdit = true
                  that.row = row
                }
              },
              {
                label: '授权',
                default(row) {
                  that.getChildrenTree(row)
                }
              },
            ]
          }
        ],
        data: [],
        page: { current: 1, pageSize: 10, total: 0 },
        row: {},
        showAddEdit: false,
        visible: false,
        checkedKeys: [],
        treeData: [],
        defHalfCheckedKeys: [],
        halfCheckedKeys: [],
        typeList: []
      }
    },
    mounted() {
      this.getTree()
      this.getType()
      this.getList()
    },
    methods: {
      async getType() {
        try {
          this.loading = true
          const { list } = await this.$request({
            url: this.$API.type.typeList,
            params: { keyword: 'ACTIONS', page: 1, pagesize: 999 }
          })
          list.forEach(item => {
            this.typeList.push(item.value)
          })
        } finally {
          this.loading = false
        }
      },
      check(checkedKeys,e) {
        // 选中节点
        this.checkedKeys = checkedKeys
        // 选中父节点
        this.halfCheckedKeys = e.halfCheckedKeys
      },
      getChildrenTree(row) {
        this.visible = true
        this.row = row
        this.treeFormate(this.treeData)
        const purview = row.purview ? JSON.parse(row.purview) : []
        const module = purview.module || []
        const actions = purview.actions || {}
        for (let i in actions) {
          // console.log(i.length)
          actions[i].forEach(item => {
            module.push(`${i}_${item}`)
          })
        }
        /* 去除父节点 */
        this.defHalfCheckedKeys.forEach(n => {
          module.forEach((i, j) => {
            if (i === n) {
              this.halfCheckedKeys.push(i)
              module.splice(j, 1)
            }
          })
        })
        this.checkedKeys = module
      },
      /* 单独抽出父节点 */
      treeFormate(data) {
        data.forEach(n =>{
          if (n.sub) {
            this.defHalfCheckedKeys.push(n.code)
            this.treeFormate(n.sub)
          }
        })
      },
      async handleOk() {
        try {
          this.loading = true
          const module = [], actions = {}, arr = [...this.checkedKeys, ...this.halfCheckedKeys]
          arr.forEach(item => {
              if (item.indexOf('_') > -1) {
                const a = item.split('_')
                if (!actions[a[0]]) {
                  actions[a[0]] = []
                  actions[a[0]].push(a[1])
                } else {
                  actions[a[0]].push(a[1])
                }
              } else {
                module.push(item)
              }
          })
          const data ={
            id: this.row.id,
            purview: JSON.stringify({ module, actions })
          }
          await this.$request({
            url: API.departmentManagement.groupGrant,
            method: 'post',
            data
          })
          this.$message.success('用户组修改授权成功');
          this.visible = false
          await this.getList()
          this.$store.dispatch('GetInfo')
        } finally {
          this.loading = false
        }
      },
      async getTree() {
        try {
          this.loading = true
          this.treeData = await this.$request({
            url: API.menu.MenuTree
          })
        } finally {
          this.loading = false
        }
      },
      async getList() {
        try {
          this.loading = true
          const params = {
            page: this.page.current,
            pagesize: this.page.pageSize,
            ...this.form
          }
          const { list, total } = await this.$request({
            url: API.departmentManagement.groupList,
            params
          })
          this.data = list
          this.page.total = total
        } finally {
          this.loading = false
        }
      },
      handleSearch() {
        this.page.current = 1
        this.getList()
      },
      handleReset() {
        this.form = {}
        this.handleSearch()
      },
      onChange(page) {
        this.page = page
        this.getList()
      }
    }
  }
</script>
